import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import QuoteMarkStart from '../../svg/OfeQuotemark1'
import QuoteMarkEnd from '../../svg/OfeQuotemark2'

const Slide = styled.div`
  position: relative;
  height: 100% !important;

  .gatsby-image-wrapper {
    height: 100% !important;
  }
`

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`

const OverlayContent = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 767px;
  margin: auto 6rem;
  padding: 3rem;
  border-radius: 0.25rem;
  color: white;

  svg {
    width: 100%;
    max-height: 50%;
    max-width: 75px;
    color: white;
  }

  @media screen and (max-width: 767px) {
    margin: 0 15vw;
    font-size: 2vw;
    padding: 0.5rem;

    svg {
      max-width: 5vw !important;
    }
  }
`

const OverlayContentText = styled.div`
  text-align: center;
  padding: 1rem 0;

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
`
const OverlayContentCitation = styled.div`
  text-align: center;
  margin: 1rem 0;
`

const TestimonialSlide = ({ testimonial }) => {
  const { id, acf } = testimonial
  return (
    <Slide key={id}>
      <Img fluid={acf.featured_image.localFile.childImageSharp.fluid} />
      <Overlay>
        <OverlayContent>
          <QuoteMarkStart />
          <OverlayContentText
            dangerouslySetInnerHTML={{ __html: acf.content }}
          />
          <QuoteMarkEnd />
          <OverlayContentCitation
            dangerouslySetInnerHTML={{ __html: acf.citation }}
          />
        </OverlayContent>
      </Overlay>
    </Slide>
  )
}

export default TestimonialSlide
