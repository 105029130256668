import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import withSizes from 'react-sizes'
import ContentRows from './ContentRows'

const StyledSection = styled.section`
  @media screen and (max-width: 767px) {
    .columns {
      display: flex;
      flex-flow: column-reverse;

      &:first-child {
        margin-top: 3rem !important;
      }
    }

    .columns:nth-child(2n + 1) {
      flex-flow: column;
    }
  }
`

const SectionHeading = styled.h2`
  margin: 3rem 0;
  font-weight: 100 !important;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    margin: 3rem 0 0;
  }
`

const OurStory = ({ isDesktop, contentRows }) => {
  const [visible, setVisible] = useState(isDesktop)

  useEffect(() => {
    setVisible(isDesktop)
  }, [isDesktop])

  const handleToggle = () => {
    if (!isDesktop) {
      setVisible(!visible)
    }
  }
  return (
    <StyledSection>
      <span id="our-story" />
      <SectionHeading
        className="is-size-2 has-text-centered toggle"
        onClick={handleToggle}
        onKeyUp={handleToggle}
      >
        Our Story
      </SectionHeading>
      {visible && (
        <article>
          <ContentRows rows={contentRows} />
        </article>
      )}
    </StyledSection>
  )
}

const mapSizesToProps = ({ width }) => ({
  isMobile: !width || width < 768,
  isDesktop: !width || width >= 768
})

export default withSizes(mapSizesToProps)(OurStory)