import React from 'react'
import HeroCarousel from '../components/Content/Hero/HeroCarousel'
import FeaturedCaseStudies from '../components/Content/FeaturedCaseStudies'
import Leadership from '../components/Content/Leadership'
import TestimonialCarousel from '../components/Content/TestimonialCarousel'
import OurStory from '../components/Content/OurStory'

const PageTemplate = ({ page }) => {
  const { acf } = page

  return (
    <>
      <HeroCarousel />
      <OurStory contentRows={acf.content_rows} />
      <FeaturedCaseStudies />
      <Leadership />
      {acf.show_testimonial_slider && (
        <TestimonialCarousel />
      )}
    </>
  )
}

export default PageTemplate