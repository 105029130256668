import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Bio from './Bio'

const SectionWrapper = styled.section`
  margin: 6rem auto !important;
`

const SectionHeading = styled.h2`
  margin: 3rem 0 3rem;
  font-weight: 100 !important;
`

const SectionBios = styled.div`
  max-width: 960px;
  margin: 0 auto !important;

  .gatsby-image-wrapper {
    flex: 1;
  }
`

const Leadership = () => {
  const data = useStaticQuery(graphql`
    query LeadershipQuery {
      wordpressPage(slug: { eq: "home" }) {
        acf {
          leadership {
            bio {
              post_title
              acf {
                position
                content
                featured_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 768, maxHeight: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const bios = data.wordpressPage.acf.leadership

  return (
    <SectionWrapper className="container">
      <span id="who-we-are" />
      <SectionHeading className="is-size-2 has-text-centered">
        LEADERSHIP
      </SectionHeading>

      <SectionBios className="columns is-multiline">
        {bios.map(node => {
          return <Bio bio={node.bio} key={node.bio.post_title} />
        })}
      </SectionBios>
    </SectionWrapper>
  )
}

export default Leadership
