import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const BioWrapper = styled.article`
  position: relative;
  margin: 1rem 0 !important;
`

const BioImage = styled.div`
  display: flex !important;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
`

const BioContent = styled.div`
  display: flex !important;
  flex-direction: column;
  position: relative;
`

const BioText = styled.div`
  margin: 1rem 0;

  p {
    margin-bottom: 1rem;
  }
`

const Bio = ({ bio }) => {
  const { featured_image, position, content } = bio.acf
  return (
    <BioWrapper className="columns" key={bio.post_name}>
      <BioImage className="column is-one-third">
        <Img fluid={featured_image.localFile.childImageSharp.fluid} />
      </BioImage>
      <BioContent className="column is-two-thirds">
        <h3 className="is-size-5">{bio.post_title}</h3>
        <h3 className="is-size-5">{position}</h3>
        <BioText dangerouslySetInnerHTML={{ __html: content }} />
      </BioContent>
    </BioWrapper>
  )
}

export default Bio
