import React from 'react'

const SvgOfeQuotemark1 = props => (
  <svg viewBox="0 0 1080 1080" {...props}>
    <path
      d="M185.9 600.91c0-171.67 78.21-263.03 282.22-326.72l40.81 69.23c-146.21 60.9-200.61 121.82-190.42 227.03h91.8v235.36H185.9v-204.9zm385.19 0c0-171.67 78.21-263.03 282.21-326.72l40.81 69.23C747.9 404.32 693.5 465.24 703.69 570.45h91.82v235.36H571.09v-204.9z"
      fill="#e7f5fe"
    />
  </svg>
)

export default SvgOfeQuotemark1
