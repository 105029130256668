import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import EventTile from './Events/EventTile'

const SectionWrapper = styled.section``

const SectionHeading = styled.h2`
  margin: 3rem 0;
  font-weight: 100 !important;
`

const SectionFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  font-size: 1.4rem;

  a {
    color: ${props => props.theme.text};
  }
`

const FeaturedCaseStudies = () => {
  const data = useStaticQuery(graphql`
    query EventsQuery {
      wordpressPage(slug: { eq: "home" }) {
        acf {
          featured_case_studies {
            case_study {
              acf {
                featured_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 768, maxHeight: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              post_title
              post_name
            }
          }
        }
      }
    }
  `)

  const events = data.wordpressPage.acf.featured_case_studies

  return (
    <SectionWrapper>
      <span id="our-work" />
      <SectionHeading className="is-size-2 has-text-centered">
        SOME OF OUR WORK
      </SectionHeading>

      <div className="columns is-multiline is-gapless">
        {events.map(event => {
          const { featured_image } = event.case_study.acf
          const slug = event.case_study.post_name
          const title = event.case_study.post_title
          return (
            <EventTile
              slug={slug}
              title={title}
              featuredImage={featured_image}
              key={slug}
            />
          )
        })}
      </div>

      <SectionFooter>
        <Link to="/case-studies">SEE MORE OF OUR WORK</Link>
      </SectionFooter>
    </SectionWrapper>
  )
}

export default FeaturedCaseStudies
