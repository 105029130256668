import React from 'react'

const SvgOfeQuotemark2 = props => (
  <svg viewBox="0 0 1080 1080" {...props}>
    <path
      d="M894.1 479.09c0 171.67-78.21 263.03-282.22 326.72l-40.81-69.23c146.21-60.9 200.61-121.82 190.42-227.03h-91.8V274.19H894.1v204.9zm-385.19 0c0 171.67-78.21 263.03-282.21 326.72l-40.81-69.23c146.21-60.9 200.61-121.82 190.42-227.03h-91.82V274.19H508.9l.01 204.9z"
      fill="#e7f5fe"
    />
  </svg>
)

export default SvgOfeQuotemark2
