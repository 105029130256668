import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Carousel from 'nuka-carousel'
import styled from 'styled-components'
import OfeLogo from '../../../svg/OfeLogo'

const CarouselWrapper = styled.div`
  position: relative;
  height: 100vh;

  @media screen and (min-width: 768px) {
    margin-bottom: 3rem;
  }
`

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;

  path {
    transition: fill 0.4s ease;
  }
`

const Description = styled.h2`
  font-size: 1.4rem;
  font-weight: 200 !important;
  color: white;
`

const HeroCarousel = () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      wordpressPage(slug: { eq: "home" }) {
        acf {
          hero_carousel {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      wordpressSiteMetadata {
        description
        name
      }
    }
  `)

  const { description, name } = data.wordpressSiteMetadata

  const shuffledImages = data.wordpressPage.acf.hero_carousel
    .map(a => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map(a => a.value)

  return (
    <CarouselWrapper>
      <Overlay>
        <span>
          <h1 className="is-sr-only">{name}</h1>
        </span>
        <OfeLogo style={{ height: '50%', width: '100%' }} />
        <Description className="is-size-4-mobile has-text-centered">
          {description}
        </Description>
      </Overlay>
      <Carousel
        autoplay
        speed={10000}
        withoutControls
        wrapAround
        transitionMode="fade"
        cellSpacing={0}
        heightMode="max"
      >
        {shuffledImages.map(imageNode => {
          return (
            <Img
              fluid={imageNode.localFile.childImageSharp.fluid}
              key={imageNode.localFile.childImageSharp.fluid.src}
            />
          )
        })}
      </Carousel>
    </CarouselWrapper>
  )
}

export default HeroCarousel
