import React from 'react'
import { graphql } from 'gatsby'
import GlobalLayout from '../components/Layout'
import PageTemplate from '../templates/page'

const Page = ({ data }) => {

  return (
    <GlobalLayout
      title={data.wordpressPage.title}
      themeOption={data.wordpressAcfOptions}
      categoryColors={data.allWordpressAcfCategories}
      imageUrl={data.wordpressPage.featured_media.source_url}
    >
      <PageTemplate
        title={data.wordpressPage.title}
        page={data.wordpressPage}
      />
    </GlobalLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageQuery {
    wordpressPage(slug: { eq: "home" }) {
      wordpress_id
      id
      title
      excerpt
      slug
      type
      date
      featured_media {
        source_url
      }
      acf {
        content_rows {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          text
        }
        show_testimonial_slider
        testimonials {
          testimonial {
            wordpress_id
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        theme {
          name
          slug
          term_id
        }
      }
    }
    allWordpressAcfCategories {
      edges {
        node {
          acf {
            theme_color
            theme_text_color
          }
          wordpress_id
        }
      }
    }
  }
`
