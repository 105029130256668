import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import withSizes from 'react-sizes'

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 3rem 4vw;

  h1,
  h2,
  h3,
  p {
    margin-bottom: 1rem;
    font-weight: 300 !important;
  }

  h3 {
    font-size: 1.6rem;
  }
`

const ContentRows = ({ rows, isDesktop }) => {
  const direction = isDesktop ? 'row' : 'column'
  return (
    <>
      {rows &&
        rows.map((row, index) => {
          const styles = {
            flexDirection: !isDesktop ? direction : index % 2 ? direction : `${direction}-reverse`
          }
          return (
            <div
              className="columns is-paddingless is-gapless"
              style={styles}
              key={index} >
              <div className={direction}>
                <CenteredContent
                  dangerouslySetInnerHTML={{ __html: row.text }}
                />
              </div>
              <div className="column is-two-fifths">
                <Img fluid={row.image.localFile.childImageSharp.fluid} />
              </div>
            </div>
          )
        })}
    </>
  )
}

const mapSizesToProps = ({ width }) => ({
  isMobile: !width || width < 768,
  isDesktop: !width || width >= 768
})

export default withSizes(mapSizesToProps)(ContentRows)
